import React from "react";

import { FiMail, FiPhoneCall } from "react-icons/fi";

const ContactItems = () => {
  return (
    <>
      <h3 className="contact-item mt-5">
        <FiPhoneCall />
        <a href="tel:416-936-5968" className="ml-3">
          (416)-936-5968
        </a>
      </h3>

      <h3 className="contact-item mt-5 mb-4">
        <FiMail />
        <a href="mailto:info@kedafenterprises.com" className="ml-3">
          info@kedafenterprises.com
        </a>
      </h3>
    </>
  );
};

export default ContactItems;
